
import { Routes, Route} from "react-router-dom";
import { Suspense, lazy } from "react";



export const Loadable = (Component) => (props) => {
  return (
    <Suspense>
      <Component {...props} />
    </Suspense>
  );
};

const Home = Loadable(lazy(() => import("../pages")));


const Router = () => {

 
  
    return (
      <>
        
        <Routes>
          
            <Route path='/' element={<Home />} />

        </Routes>
      </>
    );
};

export default Router;
